import React from 'react'

import '../../scss/page-header.scss'

const PageHeader = ({ children }) => {
    return (
        <section className="page-header">
            <div className="container">
                {children}
            </div>
        </section>
    )
}

export default PageHeader