import React from 'react'
import { graphql } from 'gatsby'
import Seo from "gatsby-plugin-wpgraphql-seo"
import parse from 'html-react-parser'

import Layout from '../components/layout'

import Hero from "../components/template-parts/hero"
import ProgramCard from "../components/template-parts/program-card"
import PageHeader from '../components/template-parts/page-header'
import CrisisBar from '../components/template-parts/crisis-bar'

import '../scss/program-category.scss'

const ProgramCategory = ({ data: { allWpProgram, page } }) => {
    
    const { programs } = allWpProgram;

    const { pageContent: { programCategory: { pageHeader, crisisBar } } } = page
    
    return (
        <Layout>
            <Seo post={page} />
            <Hero page={page} />

            {pageHeader.title && (
                <PageHeader>
                    <h2>
                        {pageHeader.title}
                    </h2>
                    {pageHeader.content && parse(pageHeader.content)}
                </PageHeader>
            )}

            <CrisisBar data={crisisBar} />

            <section className="program-list">
                <div className="container">
                    {programs && programs.length && programs.map(program => (
                        <ProgramCard key={program.id} program={program} />
                    ))}
                </div>
            </section>

        </Layout>
    )
}

export default ProgramCategory

export const pageQuery = graphql`#graphql 
    query ProgramCategoryQuery( $id: String!, $slug: String! ) {
        allWpProgram( filter: { programCategories: { nodes: { elemMatch: { id: { eq: $id } } } } }, sort: { order: DESC, fields: menuOrder } ) {
            programs: nodes {
                id
                title
                slug
                content
                featuredImage {
                    image: node {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                    layout: FULL_WIDTH
                                    aspectRatio: 1.5
                                )
                            }
                        }
                    }
                }
                programData {
                    summary {
                        summaryContent
                        button {
                            target
                            title
                            url
                        }
                    }
                    finePrint
                    testimonials {
                        content
                        name
                    }
                    details {
                        video
                        title
                        image {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        quality: 100
                                        placeholder: BLURRED
                                        layout: FULL_WIDTH
                                        aspectRatio: 1.5
                                    )
                                }
                            }
                        }
                        fieldGroupName
                        content
                        button {
                            target
                            title
                            url
                        }
                    }
                }
            }
        }
        page: wpPage(slug: {eq: $slug } ) {
            id
            slug
            title
            content
            seo {
                title
                metaDesc
                focuskw
                metaKeywords
                opengraphTitle
                opengraphDescription
                opengraphImage {
                    altText
                    sourceUrl
                    srcSet
                }
                twitterTitle
                twitterDescription
                twitterImage {
                    altText
                    sourceUrl
                    srcSet
                }
                canonical
                cornerstone
                schema {
                    articleType
                    pageType
                    raw
                }
            }
            hero {
                backgroundImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 2000
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, JPG]
                            )
                        }
                    }
                }
                title
                content
                largeHero
            }
            pageContent {
                pageTemplate
                programCategory {
                    pageHeader {
                        content
                        title
                    }
                    crisisBar {
                        button {
                            url
                            title
                            target
                        }
                        content
                    }
                }
            }
        }
    }
`
