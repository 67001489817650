import React from 'react'
import { Link } from 'gatsby'
import parse from 'html-react-parser'

import '../../scss/crisis-bar.scss'

const CrisisBar = ({ data }) => {

    return (
        <section className="crisis-bar">
            <div className="container">
                <div className="row">
                    <div className="col">
                        {data.content ? parse(data.content) : ''}
                    </div>
                    <div className="col">
                        {data.button && data.button.title && (
                            <Link to={data.button.url} className={`button blue ${data.button.title.toLowerCase().replace(/\s/g, '')}`} target={data.button.target}>
                                {data.button.title}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CrisisBar
