import React, { Fragment } from 'react'
import parse from 'html-react-parser'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import '../../scss/program-card.scss'

const ProgramCard = ({ program }) => {

    const { programData: { summary } } = program

    return (
        <article className='program-item'>
            <div className="wrapper">
                <div className="image">
                    {program?.featuredImage?.image?.localFile?.childImageSharp?.gatsbyImageData ? (
                        <Fragment>
                            {summary?.button?.url ? (
                                <Link to={summary.button.url}>
                                    <GatsbyImage
                                        objectFit="contain"
                                        alt={program.featuredImage.image.altText}
                                        image={program?.featuredImage?.image?.localFile?.childImageSharp?.gatsbyImageData}
                                    />
                                </Link>
                            ) : (
                                <Link to={`/program/${program.slug}`}>
                                    <GatsbyImage
                                        objectFit="contain"
                                        alt={program.featuredImage.image.altText}
                                        image={program?.featuredImage?.image?.localFile?.childImageSharp?.gatsbyImageData}
                                    />
                                </Link>
                            )}
                        </Fragment>
                    ) : (
                        <div className="placeholder" />
                    )}
                </div>
                <div className="content">
                    <h3>
                        {program?.title}
                    </h3>
                    {summary?.summaryContent && parse(summary.summaryContent)}
                    {summary?.button?.url ? (
                        <Link to={summary.button.url} className="button blue" target={summary.button.target}>
                            {summary.button.title}
                        </Link>
                    ) : (
                        <Link to={`/program/${program.slug}`} className="button blue">
                            Learn More
                        </Link>
                    )}
                </div>
            </div>
        </article>
    )
}

export default ProgramCard
